import "./App.css";
import { useEffect } from "react";
function App() {
  useEffect(() => {
    fetch("https://tylorcorp.onrender.com/healthz")
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  }, []);
  return (
    <div className="App">
      <header className="App-header">
        <img src={"TylorCorp512.png"} className="App-logo" alt="logo" />
        <p>You probably meant to go to TylorCorp, right?</p>
        <a
          className="App-link"
          href="https://tylorcorp.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          Go To TylorCorp
        </a>
      </header>
    </div>
  );
}

export default App;
